import React, { useState, useEffect } from "react";
import { ErrorBoundary } from "storybook-dashboard/errors";
import Im from "immutable";
import { styledTab, activeStyledTab } from "storybook-dashboard/utils";
import SummaryTab from "./dashboard/tabs/SummaryTab.js";
import AnalyseHotspotsTab from "./dashboard/tabs/AnalyseHotspotsTab.js";
import SupplierBreakdownTab from "./dashboard/tabs/SupplierBreakdownTab.js";
import SuppliersReportedTab from "./dashboard/tabs/SuppliersReportedTab.js";
import useApi from "storybook-dashboard/utils/fetching";

export default function DashboardComponents({ projectId, refId, companyId, iconPath }) {
  let [selectedIssue, setSelectedIssue] = useState(null);
  let [selectedIndicatorsById, setSelectedIndicatorsById] = useState(Im.Map()); // A map of id: indicator data for selected indicators
  let [filters, setFilters] = useState(Im.Map());
  const [activeTab, setActiveTab] = useState("summary");

  // Reset the selected indicators to none if the issue selection changes
  useEffect(() => {
    setSelectedIndicatorsById(Im.Map());
  }, [selectedIssue]);

  // Simplify the selection of indicators to just give a list of objects {uid, name}
  let selectedIndicators = selectedIndicatorsById.toList();

  let { data: reportedSuppliers } = useApi(`/api/dashboard/project/${projectId}/reporting_package/status`);

  const numberOfReportedSuppliers = reportedSuppliers?.size;

  const dashboardTabs = [
    {
      id: "summary",
      name: "Summary",
      clientOnly: false,
    },
    {
      id: "supplierBreakdown",
      name: "Supplier breakdown",
      clientOnly: true,
    },
    {
      id: "suppliersReported",
      name: "Supplier engagement",
      size: numberOfReportedSuppliers || null,
      clientOnly: true,
    },
    {
      id: "analyseHotspots",
      name: "Analyse hotspots",
      clientOnly: false,
    },
  ];

  const tabComponents = {
    summary: <SummaryTab projectId={projectId} refId={refId} companyId={companyId} />,
    supplierBreakdown: <SupplierBreakdownTab projectId={projectId} refId={refId} />,
    suppliersReported: (
      <SuppliersReportedTab
        projectId={projectId}
        refId={refId}
        companyId={companyId}
        number={numberOfReportedSuppliers}
      />
    ),
    analyseHotspots: (
      <AnalyseHotspotsTab
        projectId={projectId}
        refId={refId}
        iconPath={iconPath}
        selectedIssue={selectedIssue}
        setSelectedIssue={setSelectedIssue}
        selectedIndicators={selectedIndicators}
        selectedIndicatorsById={selectedIndicatorsById}
        setSelectedIndicatorsById={setSelectedIndicatorsById}
        filters={filters}
      />
    ),
  };

  const handleTabSwitch = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <ErrorBoundary>
      <div className="d-flex mt-1 mb-3 col-sm-12" style={{ cursor: "pointer" }}>
        {dashboardTabs.map((tab) => {
          if (!(refId === "root" || !refId) && tab.clientOnly) {
            return null;
          }

          return (
            <a
              key={tab.id}
              className={`${styledTab} ${activeTab === tab.id ? `${activeStyledTab}` : ``}`}
              onClick={() => handleTabSwitch(tab.id)}
            >
              {tab.name} {tab.size ? <span class="badge badge-primary">{tab.size}</span> : null}
            </a>
          );
        })}
      </div>
      {tabComponents[activeTab]}
    </ErrorBoundary>
  );
}
