import React, { useState } from "react";
import Im from "immutable";
import DataTable from "react-data-table-component";
import Octicon from "react-octicon";
import useApi, { useApis, usePostCache } from "storybook-dashboard/utils/fetching";
import { roundDecimals } from "storybook-dashboard/dashboard/charts/utils";
import { YearSelector, useYearSelector } from "storybook-dashboard/components/yearRangePicker";
import { getTenantConfig, getIndicatorUrl, ColumnName, columnHeader, getUnits, tableCustomStyles } from "./utils";
import Papa from "papaparse";
import { Link } from "react-router-dom";
import { SupplierLink } from "../../company/supplierReport";

const generateAndDownloadCSV = (rowData) => {
  let headers = [
    "Supplier name",
    "Total emissions (tCO2e) / £1m turnover",
    "Total suppliers emissions (tCO2e) - scopes 1, 2 & 3",
    "Total suppliers emissions (tCO2e) - scopes 1 & 2 only",
    "Scope 1 emissions (tCO2e)",
    "Scope 2 emissions (tCO2e) - location based",
    "Scope 3 emissions (tCO2e)",
    `Turnover (${getUnits()})`,
  ];

  let keys = [
    "name",
    "turnoverPerMilId",
    "totalEmissions",
    "totalEmissionsNoScope3",
    "scopeOne",
    "scopeTwo",
    "scopeThree",
    "turnover",
  ];

  let data = rowData.map((row) => keys.map((k) => row[k]));
  let csv = Papa.unparse({ fields: headers, data });

  const link = document.createElement("a");
  const filename = "supplier_breakdown.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
};

// Get the data for the tenant we are on
const requiredData = getTenantConfig();

const formatValue = (value, currency = false) => {
  return value ? `${currency ? getUnits() : ""}${roundDecimals(value)}` : "";
};

const isNumber = (v) => !isNaN(parseFloat(v));

const sumEmissions = (item) => {
  let scopes = ["scopeOne", "scopeTwo", "scopeThree"].map((key) => item.get(key));
  return scopes.reduce((a, c) => (isNumber(c) ? a + c : a), null);
};

const sumEmissionsNoScope3 = (item) => {
  let scopes = ["scopeOne", "scopeTwo"].map((key) => item.get(key));
  return scopes.reduce((a, c) => (isNumber(c) ? a + c : a), null);
};

const turnoverPerMilNoScope3 = (item) => {
  let turnover = item?.get("turnover");
  let turnoverPerMil = isNumber(turnover) ? turnover / 1000000 : null;
  let totalEmissionsNoScope3 = sumEmissionsNoScope3(item);
  let emissionsPerMilSpentNoScope3 =
    isNumber(totalEmissionsNoScope3) && isNumber(turnoverPerMil) ? totalEmissionsNoScope3 / turnoverPerMil : null;

  return emissionsPerMilSpentNoScope3;
};

const mapSupplierToValue = (indicatorData, key = "indicator_result") => {
  return indicatorData?.reduce((acc, cur) => {
    return acc?.set(cur?.get("name"), cur?.get(key));
  }, Im.Map());
};

export const SupplierBreakdownTable = ({ projectId }) => {
  const [includeScope3, setIncludeScope3] = useState(true);
  const [filterByName, setFilterByName] = useState("");
  const yearSelectorProps = useYearSelector("All time");
  const { dateRange } = yearSelectorProps;

  const year = dateRange.get("from_date") && dateRange.get("to_date") && Number(dateRange.get("from_date").slice(0, 4));
  const fetchFilters = {
    filters: {
      commit__reporting_period__endDate__gt: dateRange.get("from_date") || "2018-01-01T00:00:00", //"2022-01-01T00:00:00",
      commit__reporting_period__endDate__lte:
        dateRange.get("to_date") || `${new Date().getFullYear() + 1}-01-01T00:00:00`, //"2023-01-01T00:00:00",
    },
  };

  let _fetches = requiredData
    ?.map((value) => {
      let getUrlFunction = value.get("getUrl");
      let _id = value.get("id");
      return usePostCache(getUrlFunction(projectId, _id), fetchFilters);
    })
    ?.toJS();

  let { fetches, isLoading } = useApis(_fetches);

  // Initial reshaping of the data
  let data = requiredData?.map((value, key) => {
    let isIndicator = value.get("getUrl") == getIndicatorUrl;
    let _data = fetches[key].data;
    let imData = Im.fromJS(isIndicator ? _data?.refs : _data);
    return mapSupplierToValue(imData, isIndicator ? "indicator_result" : "value");
  });

  let supplierIds = fetches.scopeOne.data?.refs.reduce((acc, cur) => {
    return acc?.set(cur.name, cur.uid);
  }, Im.Map());

  let dataByClient = data.reduce((acc, clientsData, key) => {
    clientsData?.map((value, name) => {
      let data = acc.get(name) || Im.Map({ name });
      acc = acc.set(name, data.set(key, value));
    });
    return acc;
  }, Im.Map());

  const dataWithSupplierIds = dataByClient.mergeDeepWith((oldVal, newVal, key) => {
    return oldVal.set("uid", supplierIds.get(key));
  }, supplierIds);

  let rowData = dataWithSupplierIds
    .toList()
    .map((i) =>
      i
        .set("totalEmissions", sumEmissions(i))
        .set("totalEmissionsNoScope3", sumEmissionsNoScope3(i))
        .set("turnoverPerMilNoScope3", turnoverPerMilNoScope3(i))
    )
    ?.toJS();

  const filteredRowData = rowData?.filter(
    (item) => item.name && item.name.toLowerCase().includes(filterByName.toLowerCase())
  );

  let allScopesColumns = [
    {
      name: <ColumnName title="Supplier name" />,
      selector: (item) => item.name,
      sortable: true,
      cell: (item) => (
        <Link to={`/project/${projectId}/wpack/${item?.uid?.substring(0, 8)}/evals`} className="link" target="_blank">
          <SupplierLink>{item.name}</SupplierLink>
        </Link>
      ),
      grow: 1,
      show: "always",
    },
    {
      name: <ColumnName title="Total emissions (tCO2e) / £1m turnover" />,
      selector: (item) => item.turnoverPerMilId,
      format: (item) => formatValue(item.turnoverPerMilId),
      sortable: true,
      show: "scope3",
    },
    {
      name: <ColumnName title="Scope 1 & 2 emissions (tCO2e) / £1m turnover" />,
      selector: (item) => item.turnoverPerMilId,
      format: (item) => formatValue(item.turnoverPerMilNoScope3),
      sortable: true,
      show: "scope1and2only",
    },
    {
      name: <ColumnName title="Total suppliers emissions (tCO2e) - scopes 1, 2 & 3" />,
      selector: (item) => item.totalEmissions,
      format: (item) => formatValue(item.totalEmissions),
      sortable: true,
      show: "scope3",
    },
    {
      name: <ColumnName title="Total suppliers emissions (tCO2e) - scopes 1 & 2 only" />,
      selector: (item) => item.totalEmissionsNoScope3,
      format: (item) => formatValue(item.totalEmissionsNoScope3),
      sortable: true,
      show: "scope1and2only",
    },
    {
      name: <ColumnName title="Scope 1 emissions (tCO2e)" />,
      selector: (item) => item.scopeOne,
      format: (item) => formatValue(item.scopeOne),
      sortable: true,
      show: "always",
    },

    {
      name: <ColumnName title="Scope 2 emissions (tCO2e) - location based" />,
      selector: (item) => item.scopeTwo,
      format: (item) => formatValue(item.scopeTwo),
      sortable: true,
      show: "always",
    },
    {
      name: <ColumnName title="Scope 3 emissions (tCO2e)" />,
      selector: (item) => item.scopeThree,
      format: (item) => formatValue(item.scopeThree),
      sortable: true,
      show: "scope3",
    },
    {
      name: <ColumnName title={`Turnover (${getUnits()})`} />,
      selector: (item) => item.turnover,
      format: (item) => formatValue(item.turnover, true),
      sortable: true,
      show: "always",
    },
  ];

  let columns = allScopesColumns.filter((col) => {
    if (includeScope3) return col.show == "always" || col.show == "scope3";
    return col.show == "always" || col.show == "scope1and2only";
  });

  const handleFilterChange = (e) => {
    setFilterByName(e.target.value);
    console.log(filterByName, "filterByNameHere");
  };

  return (
    <div className="shadow col-sm-12 bg-white rounded my-3 p-3">
      <div className="d-flex flex-row flex-wrap justify-content-between align-items-center p-0 mb-3">
        <div className="col-sm-5 px-0">
          <input
            className="form-control form-control-sm"
            placeholder={`Filter by Supplier name`}
            onChange={handleFilterChange}
          />
        </div>
        <div className="d-flex flex-row">
          <div className="form-check d-flex align-items-center mr-2">
            <input
              className="form-check-input"
              type="checkbox"
              checked={includeScope3}
              onChange={(e) => {
                setIncludeScope3(e.target.checked);
              }}
              id="scope3SupplierBreakdown"
            />
            <label className="form-check-label" for="scope3SupplierBreakdown">
              Include scope 3 emissions
            </label>
          </div>
          <YearSelector {...yearSelectorProps} includeCustom={false} includeNone={true} />
          <button className="btn btn-link ml-2" onClick={() => generateAndDownloadCSV(rowData)}>
            <Octicon name="cloud-download" /> Export to csv
          </button>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={filteredRowData}
        fixedHeader={true}
        pagination={true}
        customStyles={tableCustomStyles}
      />
    </div>
  );
};

export default SupplierBreakdownTable;
